<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

import NoticeManageTableForm from './components';

formCreate.component('NoticeManageTableForm', NoticeManageTableForm);

export default {
  extends: Form,
  data() {
    return {
      rule: [],
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  async mounted() {
    this.rule = await this.getFormRule('main_form');
    this.rule.map((v) => {
      const rowData = v;
      if (rowData.field === 'fileList') {
        rowData.props.showFileList = true;
        rowData.props.uploadType = 'file';
        rowData.props.listType = 'text';
        rowData.props.fileMsg = '只能上传pdf/word/excel/ppt/png/jpg文件';
        rowData.props.accept = '.pdf,.doc,.docx,.xlsx,.xls,.ppt,.pptx,.png,.jpg';
        if (this.formConfig.code === 'view') {
          rowData.props.disabled = true;
        }
      } else if (rowData.field === 'endTime' || rowData.field === 'beginTime') {
        rowData.props = {
          ...rowData.props,
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          relation: {
            beginField: 'beginTime',
            endField: 'endTime',
          },
        };
      } else if (rowData.field === 'list') {
        rowData.props = {
          functionCode: '',
          selection: [],
          idKey: 'id',
          notView: this.formConfig.code !== 'view',
        };
        rowData.value = {
          orgList: [],
        };
      } else if (rowData.field === 'content') {
        rowData.props = {
          ...rowData.props,
          notView: this.formConfig.code === 'view',
        };
      }
      return rowData;
    });
    this.reload(this.rule);
    console.log('🚀 ~ mounted ~ this.formConfig:', this.formConfig);
    if (this.formConfig.id) {
      this.getData();
    }
  },
  methods: {
    // 获取表单数据
    getData() {
      request
        .get('/cps/v1/fxhNotice/findById/', {
          id: this.formConfig.id,
        })
        .then((res) => {
          const data = {
            ...res.result,
            list: {
              orgList: res.result.orgList,
            },
          };
          this.setValue(data);
        });
    },
    // 提交
    async submit() {
      const params = {
        ...this.getFormData(),
        // id: this.formConfig.selection[0] || '',
        orgList: this.getFormData().list.orgList.map((v) => {
          const rowData = v;
          rowData.customerName = '';
          rowData.customerCode = '';
          rowData.containFlag = 1;
          delete rowData.id;
          return rowData;
        }),
      };
      delete params.list;
      console.log('🚀 ~ submit ~ params:', params);

      let path = '/cps/v1/fxhNotice/save';
      if (this.formConfig.id) {
        path = '/cps/v1/fxhNotice/update';
        params.id = this.formConfig.id;
      }
      request.post(path, params).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
      return true;
    },
    // // 编辑赋值
    // initEditValue() {
    //   const submitType = this.formConfig.type;
    //   if (submitType === 'add') {
    //     this.resetFields();
    //   } else if (this.formConfig.row) {
    //     request
    //       .post('/mdm/mdmfunctionsub/query', { functionCode: this.formConfig.row })
    //       .then((res) => {
    //         this.setValue(res.result);
    //       });
    //   }
    // },
  },
};
</script>
